import React from 'react'
import styled from 'styled-components'

import { Button, quizBoxMixin } from '../../parent/quiz/quiz-elements'

import startQuestionBG from '../../../images/parent/quiz/start_question_bg.svg'
import startMoneyBG from '../../../images/parent/quiz/start_money_bg.svg'

const StartWrapper = styled.div`
  ${quizBoxMixin}
  text-align: center;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1000px;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 575px) {
      content: none;
    }
  }
  &:before {
    top: 70%;
    right: 100%;
    bottom: 0;
    background-image: url(${startQuestionBG});
    background-position: right center;
    transform: translate(2%, 60%);
    pointer-events: none;
  }
  &:after {
    top: -15%;
    left: 100%;
    bottom: -15%;
    background-image: url(${startMoneyBG});
    background-position: left center;
    transform: translateX(-5%);
  }
`

const StartTitle = styled.h2`
  text-align: center;
  margin-bottom: 60px;
  span {
    display: block;
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 44px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: 36px;
    }
  }
  strong {
    position: relative;
    top: 1.2em;
    display: block;
    font-size: 22px;
    font-weight: 400;
    @media (max-width: 575px) {
      font-size: 18px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -10px;
      display: block;
      width: 107px;
      height: 4px;
      background-color: ${(props) => props.theme.colorGreenLight};
      transform: translateX(-50%);
      @media (max-width: 575px) {
        width: 90px;
        height: 3px;
      }
    }
  }
`

const StartDescWrapper = styled.div`
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 10px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
  strong {
    font-weight: 700;
  }
`

const Start = (props) => {
  const { setIsQuizStarted } = props
  return (
    <StartWrapper>
      <StartTitle>
        <span>Przeczytaj</span> <strong>zanim wypełnisz ankietę</strong>
      </StartTitle>
      <StartDescWrapper>
        <p>
          Na każde z pytań odpowiedz <strong>TAK</strong> lub{' '}
          <strong>NIE</strong>.
        </p>
        <p>Udzielaj odpowiedzi, która jako pierwsza przychodzi Ci do głowy.</p>
        <p>Pamiętaj nie ma odpowiedzi dobrych lub złych.</p>
        <p>
          Ankieta jest anonimowa, a Twoje odpowiedzi nie będą wykorzystywane do
          celów marketingowych ani przekazywane podmiotom trzecim.
        </p>
      </StartDescWrapper>
      <Button onClick={() => setIsQuizStarted(true)}>OK</Button>
    </StartWrapper>
  )
}

export default Start
