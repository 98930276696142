import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Start from '../../../components/parent/quiz/start'
import QuizQuestions from '../../../components/parent/quiz/quiz-questions'
import Finish from '../../../components/parent/quiz/finish'
import {
  QUIZ_QUESTIONS,
  QUIZ_DECIDING_QUESTIONS,
  shuffleQuestions,
} from '../../../components/parent/quiz/questions'

const QuizPageWrapper = styled.div`
  overflow: hidden;
`

const QuizPageBackgroundImage = styled(BackgroundImage)``

const QuizPageColWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
`

const QuizPage = ({ location }) => {
  const FormRef = useRef()

  const defaultPoints = {}
  const questionStyles = [...new Set(QUIZ_QUESTIONS.map((q) => q.style))]
  questionStyles.forEach((s) => (defaultPoints[s] = 0))

  const [isQuizStarted, setIsQuizStarted] = useState(false)
  const [questions, setQuestions] = useState([])
  const [questionNumber, setQuestionNumber] = useState(0)
  const [question, setQuestion] = useState()
  const [answerSelected, setAnswerSelected] = useState()
  const [points, setPoints] = useState(defaultPoints)
  const [style, setStyle] = useState()
  const [isDecidingQuestion, setIsDecidingQuestion] = useState(false)
  const [quizBG, setQizBG] = useState('statusStartBG')

  useEffect(() => {
    setQuestions(shuffleQuestions(QUIZ_QUESTIONS))
  }, [])

  useEffect(() => {
    if (questions.length > 0 && questionNumber >= 0) {
      setQuestion(questions[questionNumber])
    }
  }, [questionNumber, questions])

  useEffect(() => {
    setAnswerSelected()
  }, [question])

  useEffect(() => {
    if (isQuizStarted && !style) {
      setQizBG('statusQuestionBG')
    }
  }, [isQuizStarted, style])

  useEffect(() => {
    const result = style && style[0]

    if (result === 'mentorski') {
      setQizBG('statusMentoringBG')
    }

    if (result === 'beztroski') {
      setQizBG('statusCarefreeBG')
    }

    if (result === 'kontrolujący') {
      setQizBG('statusControllingBG')
    }
  }, [style])

  const handleQuestionSubmit = (e) => {
    e.preventDefault()

    if (!answerSelected) {
      FormRef.current.reset()
    } else {
      const newPoints = points
      if (answerSelected === 'Y' && question.points > 0) {
        newPoints[question.style] += question.points
      } else if (answerSelected === 'N' && question.points < 0) {
        const newPoints = points
        newPoints[question.style] += Math.abs(question.points)
      }
      setPoints(newPoints)
      FormRef.current.reset()

      let pointsSorted = {}
      Object.keys(points).forEach((p) => {
        if (typeof pointsSorted[points[p]] === 'undefined') {
          pointsSorted[points[p]] = []
        }
        pointsSorted[points[p]].push(p)
      })

      const nextQuestionNumber = questionNumber + 1
      if (nextQuestionNumber < questions.length && !isDecidingQuestion) {
        setQuestionNumber(nextQuestionNumber) // next question
      } else {
        // all answered
        if (
          Object.keys(pointsSorted).length > 0 &&
          pointsSorted[
            Math.max(...Object.keys(pointsSorted).map((pS) => parseInt(pS, 10)))
          ].length === 1
        ) {
          setStyle(
            pointsSorted[
              Math.max(
                ...Object.keys(pointsSorted).map((pS) => parseInt(pS, 10))
              )
            ]
          )
        } else if (isDecidingQuestion) {
          setQuestionNumber(nextQuestionNumber) // next question
        } else {
          setIsDecidingQuestion(true)
        }
      }
    }
  }

  const handleDecidingQuestionSubmit = (question) => {
    setStyle([question.style])
  }

  const data = useStaticQuery(graphql`
    query {
      statusStartBG: file(relativePath: { eq: "parent/quiz/start_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      statusQuestionBG: file(
        relativePath: { eq: "parent/quiz/question_bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      statusMentoringBG: file(
        relativePath: { eq: "parent/quiz/mentoring_bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      statusCarefreeBG: file(
        relativePath: { eq: "parent/quiz/carefree_bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      statusControllingBG: file(
        relativePath: { eq: "parent/quiz/controlling_bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO title="Quiz - Strefa Rodzica" />
      <QuizPageWrapper>
        <QuizPageBackgroundImage
          Tag="div"
          className="pageBG"
          fluid={data[quizBG].childImageSharp.fluid}
        >
          <Container>
            <Row>
              <QuizPageColWrapper xs={12}>
                {!isQuizStarted && (
                  <>
                    {/*
                QUIZ 1
              */}
                    <Start setIsQuizStarted={setIsQuizStarted} />
                  </>
                )}

                {isQuizStarted &&
                  questionNumber < questions.length &&
                  question &&
                  !style && (
                    <>
                      {/*
                QUIZ 2
              */}
                      <QuizQuestions
                        questionNumber={questionNumber}
                        isDecidingQuestion={isDecidingQuestion}
                        question={question}
                        questions={questions}
                        decidingQuestionsTitle={`Wybierz stwierdzenie, z którym najbardziej się identyfikujesz:`}
                        decidingQuestions={QUIZ_DECIDING_QUESTIONS}
                        formRef={FormRef}
                        setAnswerSelected={setAnswerSelected}
                        answerSelected={answerSelected}
                        handleQuestionSubmit={handleQuestionSubmit}
                        handleDecidingQuestionSubmit={
                          handleDecidingQuestionSubmit
                        }
                      />
                    </>
                  )}

                {isQuizStarted && style && (
                  <>
                    {/*
                QUIZ MENTORSKI – 1
                QUIZ BEZTROSKI
                QUIZ KONTROLUJACY
              */}
                    <Finish result={style[0]} />
                  </>
                )}
              </QuizPageColWrapper>
            </Row>
          </Container>
        </QuizPageBackgroundImage>
      </QuizPageWrapper>
    </Layout>
  )
}

export default QuizPage
