import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, quizQuestionsBoxMixin } from '../../parent/quiz/quiz-elements'

const QuizQuestionsWrapper = styled.div`
  ${quizQuestionsBoxMixin}
  border-top: none;
`

const QuizQuestionsTitle = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 2.5;
  transform: translateY(-1.8em);
  @media (max-width: 575px) {
    font-size: 30px;
  }
  & > span {
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0.61em;
      width: 100px;
      height: 4px;
      background-color: ${(props) => props.theme.colorGreenLight};
    }
    &:before {
      right: calc(100% + 15px);
    }
    &:after {
      left: calc(100% + 15px);
    }
    & > span {
      color: ${(props) => props.theme.colorGreenLight};
    }
  }
`

const QuizQuestionsInnerWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding-top: 15px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 45px;
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 35px;
  }
`

const QuizQuestionsTitleQty = styled.h2`
  text-align: center;
  margin-bottom: 60px;
  & > span {
    position: relative;
    display: block;
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: 26px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -0.75em;
      display: block;
      width: 90px;
      height: 4px;
      background-color: ${(props) => props.theme.colorGreenLight};
      transform: translateX(-50%);
      @media (max-width: 575px) {
        width: 80px;
        height: 3px;
      }
    }
    & > span {
      color: #a7a7a7;
    }
  }
`

const QuizQuestionsForm = styled.form``

const Question = styled.p`
  min-height: 6.2em;
  margin-bottom: 0;
  opacity: ${(props) => (props.isActive ? `1` : `0`)};
  transition: ease 0.1s all;

  @media (max-width: 767px) {
    min-height: 7.5em;
  }
  @media (max-width: 340px) {
    min-height: 8.5em;
  }
`

const QuizQuestionsDescWrapper = styled.div`
  font-size: 22px;
  font-weight: 400;
  padding-bottom: 20px;
  @media (max-width: 575px) {
    font-size: 18px;
  }

  strong {
    font-weight: 700;
  }
`

const QuizQuestionsButton = styled(Button)`
  background-color: transparent;
  @media (max-width: 340px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &.left {
    margin-right: 20px;
    @media (max-width: 575px) {
      margin-right: 10px;
    }
  }
  &.right {
    margin-left: 20px;
    @media (max-width: 575px) {
      margin-left: 10px;
    }
  }
`

const DecidingQuestionList = styled.div`
  margin-bottom: -20px;
  text-align: left;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    line-height: 1em;
    margin-left: 20px;
    margin-bottom: 5px;
    position: relative;
    transition: ease 0.2s all;

    &:before {
      border: 2px solid ${(props) => props.theme.colorGreenLight};
      content: '';
      display: block;
      left: -20px;
      height: 16px;
      position: absolute;
      top: -2px;
      transition: ease 0.2s all;
      width: 16px;
    }

    &:hover {
      color: ${(props) => props.theme.colorGreenLight} !important;

      &:before {
        background-color: ${(props) => props.theme.colorGreenLight};
      }
    }
  }
`

const QuizQuestions = (props) => {
  const {
    questionNumber,
    isDecidingQuestion,
    question,
    questions,
    decidingQuestionsTitle,
    decidingQuestions,
    formRef,
    setAnswerSelected,
    answerSelected,
    handleQuestionSubmit,
    handleDecidingQuestionSubmit,
  } = props
  const [isQuestionActive, setIsQuestionActive] = useState(false)

  const handleAnswerClick = (e, value) => {
    e.target.blur()
    setAnswerSelected(value)
    setIsQuestionActive(false)
  }

  useEffect(() => {
    setIsQuestionActive(true)
  }, [question])

  return (
    <QuizQuestionsWrapper>
      <QuizQuestionsTitle>
        <span>
          KIESZONKOWY <span>QUIZ RODZICA</span>
        </span>
      </QuizQuestionsTitle>
      <QuizQuestionsInnerWrapper>
        <QuizQuestionsTitleQty>
          {isDecidingQuestion ? (
            <>
              <span>{decidingQuestionsTitle}</span>
            </>
          ) : (
            <>
              <span>
                Pytanie {questionNumber + 1}
                {'\u00A0'}/{'\u00A0'}
                <span>{questions.length}</span>
              </span>
            </>
          )}
        </QuizQuestionsTitleQty>
        <QuizQuestionsDescWrapper>
          {isDecidingQuestion ? (
            <DecidingQuestionList>
              {decidingQuestions.map((dQ) => (
                <p>
                  <a onClick={() => handleDecidingQuestionSubmit(dQ)}>
                    {dQ.question}
                  </a>
                </p>
              ))}
            </DecidingQuestionList>
          ) : (
            <>
              <QuizQuestionsForm ref={formRef} onSubmit={handleQuestionSubmit}>
                <Question isActive={isQuestionActive}>
                  {question.question}
                </Question>
                <QuizQuestionsButton
                  type="radio"
                  value="Y"
                  id={`question-${questionNumber}-Y`}
                  name={`question-${questionNumber}`}
                  onClick={(e) => handleAnswerClick(e, 'Y')}
                  checked={answerSelected === 'Y'}
                  className="left"
                >
                  TAK
                </QuizQuestionsButton>
                <QuizQuestionsButton
                  type="radio"
                  value="N"
                  id={`question-${questionNumber}-N`}
                  name={`question-${questionNumber}`}
                  onClick={(e) => handleAnswerClick(e, 'N')}
                  checked={answerSelected === 'N'}
                  className="right"
                >
                  NIE
                </QuizQuestionsButton>
              </QuizQuestionsForm>
            </>
          )}
        </QuizQuestionsDescWrapper>
      </QuizQuestionsInnerWrapper>
    </QuizQuestionsWrapper>
  )
}

export default QuizQuestions
