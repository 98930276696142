import styled, { css } from 'styled-components'
import { Button as Btn } from 'reactstrap'

export const quizBoxMixin = css/* styled */ `
  position: relative;
  max-width: 545px;
  padding-top: 15px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 35px;
  border: 4px solid ${(props) => props.theme.colorGreenLight};
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    border-width: 3px;
  }
`

export const quizQuestionsBoxMixin = css/* styled */ `
  ${quizBoxMixin}
  position: relative;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  @media (max-width: 575px) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
`

export const Button = styled(Btn)`
  position: relative;
  display: inline-block;
  background-color: transparent;
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 28px;
  font-weight: 700;
  line-height: 0.5;
  text-transform: uppercase;
  padding-top: 0.0125em;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 1em;
  border: 3px solid ${(props) => props.theme.colorGreenLight}!important;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  transition: all 0.3s ease;
  cursor: pointer;
  @media (max-width: 575px) {
    font-size: 23px;
    padding-left: 38px;
    padding-right: 38px;
  }
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colorGreenLight}!important;
    color: ${(props) => props.theme.colorWhite};
    text-decoration: none;
  }
`
