export const QUIZ_QUESTIONS = [
  {
    question:
      'Czy często jesteś rozczarowana/rozczarowany, gdy dziecko postępuje wbrew wpajanym przez Ciebie zasadom?',
    points: 1,
    style: 'mentorski',
  },
  {
    question:
      'Czy odczuwasz dużą odpowiedzialność w związku z edukacją finansową swojego dziecka?',
    points: 1,
    style: 'mentorski',
  },
  {
    question:
      'Czy zawierasz ze swoim dzieckiem różnego rodzaju słowne umowy i dążysz do ich przestrzegania?',
    points: 1,
    style: 'mentorski',
  },
  {
    question:
      'Czy zawsze starasz się traktować swoje dziecko jak partnera (rozmawiasz, tłumaczysz, objaśniasz)?',
    points: 1,
    style: 'mentorski',
  },
  {
    question:
      'Czy dajesz dziecku swobodę działania, którą jedynie delikatnie kontrolujesz?',
    points: 3,
    style: 'mentorski',
  },

  {
    question:
      'Czy trudno Ci odmawiać swojemu dziecku spełnienia zakupowej zachcianki?',
    points: 1,
    style: 'beztroski',
  },
  {
    question: 'Czy często odmawiasz i stawiasz granice swojemu dziecku?',
    points: 1,
    style: 'beztroski',
  },
  {
    question:
      'Czy często czujesz radość i wzruszenie, gdy sprawiasz dziecku przyjemność?',
    points: 1,
    style: 'beztroski',
  },
  {
    question:
      'Czy ustalasz z dzieckiem zasady dotyczące kieszonkowego i wymagasz dostosowywania się do nich?',
    points: -3,
    style: 'beztroski',
  },
  {
    question:
      'Czy często wydajesz pieniądze spontanicznie, pod wpływem impulsu?',
    points: 1,
    style: 'beztroski',
  },

  {
    question:
      'Czy odczuwasz niepokój i/lub niepewność, gdy przekazujesz dziecku kieszonkowe?',
    points: 1,
    style: 'kontrolujący',
  },
  {
    question:
      'Czy często obawiasz się, że Twoje dziecko zrobi niewłaściwy użytek z kieszonkowego?',
    points: 1,
    style: 'kontrolujący',
  },
  {
    question:
      'Czy starasz się kontrolować, na co Twoje dziecko wydaje kieszonkowe?',
    points: 1,
    style: 'kontrolujący',
  },
  {
    question:
      'Czy sprawdzasz paragony i/lub wydatki na koncie, by wiedzieć, na co wydaje kieszonkowe Twoje dziecko?',
    points: 3,
    style: 'kontrolujący',
  },
  {
    question:
      'Uważasz, że dziecko zasadniczo popełnia błędy, nie myśli racjonalnie, ulega modom i złemu wpływowi rówieśników?',
    points: 1,
    style: 'kontrolujący',
  },
]

export const QUIZ_DECIDING_QUESTIONS = [
  {
    question:
      'Nie ustalam z dzieckiem żadnych zasad dotyczących kieszonkowego – nie mam potrzeby kontrolowania jego decyzji zakupowych i raczej rzadko to robię.',
    points: 99,
    style: 'beztroski',
  },
  {
    question:
      'Wiem, że to ważne, więc regularnie i dokładnie sprawdzam, na co moje dziecko przeznacza kieszonkowe.',
    points: 99,
    style: 'kontrolujący',
  },
  {
    question:
      'Uważam, że dziecko zasługuje na zaufanie i dużą swobodę działania finansowego, ale z umiarkowaną kontrolą i ustaleniem kluczowych zasad.',
    points: 99,
    style: 'mentorski',
  },
]

export const shuffleQuestions = (array) => {
  let currentIndex = array.length,
    randomIndex
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}
