import { withPrefix } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, quizQuestionsBoxMixin } from '../../parent/quiz/quiz-elements'

import starGreenIcon from '../../../images/star_green_icon.svg'

const FinishWrapper = styled.div`
  ${quizQuestionsBoxMixin}
  padding-top: 20px;
`

const FinishInnerWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  text-align: center;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 45px;
  @media (max-width: 575px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 35px;
  }
`

const FinishResult = styled.p`
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 50px;
  font-weight: 700;
  line-height: 0.55;
  margin-top: -0.425em;
  margin-bottom: 1.125em;
  @media (max-width: 575px) {
    font-size: 40px;
  }
`

const FinishStarsWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`

const FinishStar = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url(${starGreenIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 4px;
  margin-right: 4px;
`

const FinishDescWrapper = styled.div`
  font-size: 22px;
  font-weight: 400;
  padding-bottom: 20px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
  p {
    &:last-of-type {
      font-size: 18px;
      font-weight: 300;
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
  }
`

const FinishButton = styled(Button)`
  background-color: ${(props) => props.theme.colorGreenLight} !important;
  color: ${(props) => props.theme.colorWhite} !important;
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: ${(props) => props.theme.colorGreenLight} !important;
  }
`

const Finish = ({ result }) => {
  const [activeFile, setActiveFile] = useState()

  useEffect(() => {
    switch (result.toLowerCase()) {
      case 'mentorski':
        setActiveFile(
          withPrefix('/parent-mp3/BNP KIESZONKOWE 20220318 SC02.mp3')
        )
        break
      case 'beztroski':
        setActiveFile(
          withPrefix('/parent-mp3/BNP KIESZONKOWE 20220318 SC05.mp3')
        )
        break
      case 'kontrolujący':
        setActiveFile(
          withPrefix('/parent-mp3/BNP KIESZONKOWE 20220318 SC06.mp3')
        )
        break
    }
  }, [])

  return (
    <FinishWrapper>
      <FinishInnerWrapper>
        <FinishStarsWrapper>
          {[...Array(3)].map((_, idx) => (
            <FinishStar key={idx} />
          ))}
        </FinishStarsWrapper>
        <FinishDescWrapper>
          <p>
            Wygląda na to, że Twoje zachowania najlepiej odzwierciedlają styl
            dawania kieszonkowego, który można określić jako:
          </p>
          <FinishResult>{result.toLowerCase()}</FinishResult>
          <p>
            Specjalnie dla Ciebie wybraliśmy podcast naszego eksperta, który
            pomoże Ci w „kieszonkowej edukacji” dziecka.
          </p>

          {activeFile && (
            <audio controls>
              <source src={activeFile} type="audio/mpeg" />
            </audio>
          )}

          <FinishButton as="a" href={withPrefix('/strefa-rodzica')}>
            Przejdź do Strefy Rodzica
          </FinishButton>
        </FinishDescWrapper>
      </FinishInnerWrapper>
    </FinishWrapper>
  )
}

export default Finish
